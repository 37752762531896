<template>
  <v-data-table
    :headers="headers"
    :items="tiposItemRendimiento"
    :sort-by="['activo' , 'orden', ]"
    :sort-desc="[true, false]"
    :items-per-page="5"    
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Items Calificación Notas</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
          <v-dialog persistent v-model="dialog" max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                Items Calificación Notas
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Items Calificación</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="10">
                        <v-text-field v-model="editedItem.nombre" label="Descripción"></v-text-field>
                    </v-col>
                     <v-col cols="2">
                        <v-switch v-model="editedItem.activo" label="Activo" class="mt-2"></v-switch>                      
                    </v-col> 
                    <v-col>
                        <v-text-field v-model="editedItem.alias" label="Iniciales"></v-text-field>                      
                    </v-col>
                    <v-col>
                        <v-text-field v-model="editedItem.porcentaje" label="Valor(%)"></v-text-field>                      
                    </v-col>
                    <v-col>
                        <v-text-field v-model="editedItem.orden" label="Orden"></v-text-field>                      
                    </v-col>                    
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-toolbar>
    </template>
    <template v-slot:[`item.porcentaje`]="{ item }">
       <td>{{ obtenerPorcentajeMostrar(item.porcentaje) }}%</td>
    </template>
    <template v-slot:[`item.activo`]="{ item }">
       <v-simple-checkbox v-model="item.activo" disabled ></v-simple-checkbox>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>          
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Actualizar </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters([]),
  },
  data: () => ({
    dialog: false,
    dialogAnual: false,
    dialogCurso: false,
    dialogRegistro: false,
    tiposItemRendimiento :[],    
    editedIndex: -1,
    editedItem: {
      id: 0,
      nombre: "",      
      alias:"",
      porcentaje :0,
      orden:0,
      activo:true
    },
    defaultItem: {
      id: 0,
      nombre: "",      
      alias:"",
      porcentaje :0,
      orden:0,
      activo:true
    },
    headers: [
      { text: "Descripción", value: "nombre",sortable: true  },
      { text: "Iniciales", value: "alias",sortable: true  },
      { text: "Valor(%)", value: "porcentaje",sortable: true},
      { text: "Orden", value: "orden",sortable: true },
      { text: "Activo", value: "activo", sortable: true },
      { text: "Acciones", value: "actions", sortable: false }
    ],
  }),
  created() {
    this.initialize();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    ...mapActions([                  
      "ModificarMensaje",
      "GetTiposItemRendimiento",
      "RegistarItemRendimiento",
      "UpdateItemRendimiento"
    ]),
    initialize() {      
      this.GetTiposItemRendimiento().then((x) => (this.tiposItemRendimiento = x.data));
    },
    obtenerPorcentajeMostrar(valor){
        return parseFloat(valor) * 100.00;
    },
    obtenerPorcentajeGuardar(valor){
        return valor/100;    
    },
    save() {

      this.editedItem.porcentaje=this.obtenerPorcentajeGuardar(this.editedItem.porcentaje);

      if (this.editedIndex > -1) {
        //editar
                
        let index = this.editedIndex;
        var editItem = this.editedItem;        

        this.UpdateItemRendimiento(editItem).then((x) => {
          Object.assign(this.tiposItemRendimiento[index], editItem);
          this.ModificarMensaje({ texto: "Actualización Exitoso!" });
          this.close();
        });
      } 
      else {
        //nuevo
        this.RegistarItemRendimiento(this.editedItem).then((x) => {
          const respuesta = x.data;
          if (!!respuesta) {
            this.tiposItemRendimiento.push(respuesta);
            this.close();
            this.ModificarMensaje({ texto: "Registro Exitoso!" });
          }
        });
      }
    },
    editItem(item) {
      this.editedIndex = this.tiposItemRendimiento.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.porcentaje = this.obtenerPorcentajeMostrar(this.editedItem.porcentaje);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }
  },
};
</script>