<template>
    <TiposItemRendimiento></TiposItemRendimiento>
</template>
<script>
import TiposItemRendimiento from "@/components/TiposItemRendimiento.vue"
export default {
    components:{
        TiposItemRendimiento
    }    
}
</script>