var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tiposItemRendimiento,"sort-by":['activo' , 'orden' ],"sort-desc":[true, false],"items-per-page":5},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Items Calificación Notas")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),[_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"secondary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Items Calificación Notas ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Items Calificación")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"Descripción"},model:{value:(_vm.editedItem.nombre),callback:function ($$v) {_vm.$set(_vm.editedItem, "nombre", $$v)},expression:"editedItem.nombre"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{staticClass:"mt-2",attrs:{"label":"Activo"},model:{value:(_vm.editedItem.activo),callback:function ($$v) {_vm.$set(_vm.editedItem, "activo", $$v)},expression:"editedItem.activo"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Iniciales"},model:{value:(_vm.editedItem.alias),callback:function ($$v) {_vm.$set(_vm.editedItem, "alias", $$v)},expression:"editedItem.alias"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Valor(%)"},model:{value:(_vm.editedItem.porcentaje),callback:function ($$v) {_vm.$set(_vm.editedItem, "porcentaje", $$v)},expression:"editedItem.porcentaje"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Orden"},model:{value:(_vm.editedItem.orden),callback:function ($$v) {_vm.$set(_vm.editedItem, "orden", $$v)},expression:"editedItem.orden"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1)]],2)]},proxy:true},{key:"item.porcentaje",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.obtenerPorcentajeMostrar(item.porcentaje))+"%")])]}},{key:"item.activo",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.activo),callback:function ($$v) {_vm.$set(item, "activo", $$v)},expression:"item.activo"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Actualizar ")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }